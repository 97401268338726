import * as _ from "lodash";
export const getFilterSections = (x) => (_.keys(x).filter((y) => y !== "_id"));
export const formatFilter = (filtersState, selectedMarketplace = null) => {
  let filters = filtersState;
  if (selectedMarketplace) {
    filters = filters?.[selectedMarketplace];
  }
  if (!filters) return {};
  return getFilterSections(filters).reduce((result, filterSection) => {
    result[filterSection] = {
      controls: _.keys(filters[filterSection].controls).reduce((filterSubSection, controlName) => {
        let value = null;
        if (filters[filterSection].controls[controlName]?.checked !== undefined) {
          value = {
            checked: filters[filterSection].controls[controlName]?.checked,
            options: filters[filterSection].controls[controlName]?.options || [],
          };
        } else {
          value = { value: filters[filterSection].controls[controlName]?.value };
        }
        filterSubSection[controlName] = value;
        return filterSubSection;
      }, {}),
    };
    return result;
  }, {});
};

export const getFeeType = (filtersState, selectedMarketplace = null) => {
  let filters = filtersState;
  if (selectedMarketplace) {
    filters = filters?.[selectedMarketplace];
  }
  return filters?.profitablity?.controls?.feeType?.value;
};

export const getPriceType = (filtersState, selectedMarketplace = null) => {
  let filters = filtersState;
  if (selectedMarketplace) {
    filters = filters?.[selectedMarketplace];
  }
  return filters?.profitablity?.controls?.priceType?.value;
};

export const getCostType = (filtersState, selectedMarketplace = null) => {
  let filters = filtersState;
  if (selectedMarketplace) {
    filters = filters?.[selectedMarketplace];
  }
  const { priceType, valueType } = filters?.profitablity?.controls ?? {};
  const costType = `${parseName[priceType?.value]}${parseName[valueType?.value]}`;
  return { value: costType, priceLable: priceType?.value, valueLable: valueType?.value };
};

export const numericOptions = [
  {
    value: "Less Than",
    label: "Less Than",
    operator: "<",
  },
  {
    value: "Greater Than",
    label: "Greater Than",
    operator: ">",
  },
  {
    value: "Equal To",
    label: "Equal To",
    operator: "==",
  },
];

export const strOptions = [
  {
    value: "contains",
    label: "Contains",
    operator: "contains",
  },
  {
    value: "notContains",
    label: "Does Not Contain",
    operator: "notContains",
  },
];

export const numericValidation = {
  required: { value: true, errorMessage: "Please enter a value" },
  pattern: { value: /^-?\d*(\.\d+)?$/, errorMessage: "The value must be a number" },
};

export const requiredValidation = {
  required: { value: true, errorMessage: "Please enter a value" },
};

export const salesRankOptions = [
  {
    value: "Last Captured",
    label: "Last Captured Value",
    name: "salesRank",
  },
  {
    value: "30-Day Avg",
    label: "30-Day Moving Average",
    name: "salesRank30Days",
  },
  {
    value: "60-Day Avg",
    label: "60-Day Moving Average",
    name: "salesRank60Days",
  },
  {
    value: "90-Day Avg",
    label: "90-Day Moving Average",
    name: "salesRank90Days",
  },
  {
    value: "180-Day Avg",
    label: "180-Day Moving Average",
    name: "salesRank180Days",
  },
];

export const parseName = new Proxy({
  "Buy Box+": "buyboxPlus",
  "Buy Box": "buybox",
  "Lowest FBA": "lowestFba",
  "Lowest Offer": "lowestOffer",
  FBA: "fba",
  FBM: "fbm",
  WFS: "wfs",
  None: "None",
  "Last Captured Value": "",
  "30-Day Moving Average": "30Days",
  "60-Day Moving Average": "60Days",
  "90-Day Moving Average": "90Days",
  "180-Day Moving Average": "180Days",
  walmart: "walmart_products",
  amazon: "amazon_products",
  ebay: "ebay_products",
  homedepot: "homedepot_products",
  nike: "nike_products",
  zappos: "zappos_products",
  dsw: "dsw_products",
  undefined: "",
}, {
  get: (target, prop) => prop in target ? target[prop] : "",
});
